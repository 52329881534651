import React from "react"
import Layout from "@components/kaigai-fx/layout";
import 'tw-elements';

// 関数呼び出し
import { TermComponent } from "@components/kaigai-fx/term-component.js";

const TermPageH = () => {
  // 設定項目 ================================================================================================
  const myLine = 'は行'
  const myID = 'ha_'
  const pathName = 'term-ha';
  const termItem = [ // 改行するときは<br>を追加
    {
      Title:'始値',
      Content:'取る引きをしている期間の中で最初に売買が成立した価格のこと      '
    },
    {
      Title:'BID',
      Content:'FX業者が提示している買値のことで、トレーダーなど顧客側から見て売る場合の値段を表します。'
    },
    {
      Title:'PIPS',
      Content:'pip(ピプス)は「percentage in point」の略で、FXでは通貨ペアの値幅を表す共通単位として使用します。<br>異なる通貨ペアでも共通の単位で変動幅を表すことができ、買値と売値の差を表すスプレッドの単位でも用いられます。'
    },
    {
      Title:'評価損益',
      Content:'評価損益とは、保有資産の購入時の価格と現在の価格（時価）の差額のことです。<br>売却した場合の課税額を計算する際の基準となる損益なので、どれくらいの値上がり益（値下がり損）があり、課税されるのかなどの参考にすることができます。'
    }
  ]
  // =================================================================================================================================
  // term-component.jsにて詳細設定
  let termPageContent = TermComponent( myLine, myID, termItem, pathName ); 
  
  return (
  <>
    <Layout>
      {termPageContent}
    </Layout>
  </>
  )
}
export default TermPageH